import React from "react";
import { publish } from "../utilities/Events";

interface Parameters {
  keyLabel: string;
  key?: string;
}

const Key = ({ keyLabel }: Parameters) => {
  let key = keyLabel.toUpperCase();
  const selectLetter = () => {
    if (key.length > 1 && key !== "ENTER" && key !== "DELETE") {
      key = " ";
    }
    publish("keyTapped", { key });
  };
  let size = "";
  if (keyLabel.length > 1) {
    size = keyLabel.length < 8 ? "big" : "spacebar";
  }
  let keyLabelJsx: any;
  if (key === "DELETE") {
    size = "medium";
    keyLabelJsx = (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 0 24 24"
        width="20"
      >
        <path
          fill="var(--text-color)"
          d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"
        ></path>
      </svg>
    );
  }

  return (
    <div className={`key ${size}`} onClick={selectLetter}>
      {keyLabelJsx ?? keyLabel}
    </div>
  );
};

export default Key;
